<template>
  <v-col cols="10">
    <v-row>
      <v-col cols="12">
        <ReviewForm
          v-if="reviewFormVisible"
          :reservation-id="reservation.id"
          :property-id="reservation.property.id"></ReviewForm>
        <FeedbackForm
          v-if="reviewFormVisible"
          :reservation-id="reservation.id"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col col="4">
        <CardMiniatureHorizontalDemand
          :demand="reservation"
          :demand-price="reservation.price"
          :property-id="reservation.property.id"
          :room-id="reservation.room.id"
        />
      </v-col>
      <v-col cols="8">
        <DemandForm
          :demand="reservation"
          :inputs-disabled="true"
        />
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import DemandForm from '@/components/demands/DemandForm'
import CardMiniatureHorizontalDemand from '../../components/property/DemadPropertyCardMiniature'
import ReviewForm from '@/components/reviews/ReviewForm'
import FeedbackForm from '@/components/feedback/FeedbackForm'
export default {
  name: 'ReservationDetail',
  props: {
    reservation: {
      type: Object,
      required: true
    }
  },
  components: {
    FeedbackForm,
    DemandForm,
    CardMiniatureHorizontalDemand,
    ReviewForm
  },
  computed: {
    reviewFormVisible () {
      console.log('here')
      const days = process.env.VUE_APP_REVIEW_CREATE_TIME
      const today = new Date((new Date()).toISOString().slice(0, 10))
      const dateTo = new Date(this.reservation.dateTo)
      const maxDate = new Date()
      maxDate.setTime(dateTo.getTime() + days * 24 * 60 * 60 * 1000)

      if (today.getTime() < dateTo.getTime()) {
        return false
      }
      if (today.getTime() > maxDate.getTime()) {
        return false
      }
      return true
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-form v-model="formValid">
    <div class="display-1 text-center pt-3 pb-3">Hodnocení ubytování</div>
    <template v-if="!fetchingReview">
      <v-row>
        <v-col cols="6">
          <v-textarea
            v-model="form.text"
            label="Slovní hodnocení ubytování"
            outlined
            :disabled="!!review.id && review.approvalProcessState !== 2"
            color="red"
            class="rounded-0"
            rows="5"
          >
          </v-textarea>
        </v-col>
        <v-col class="align-center" cols="3">
          <div class="d-flex">
            <div class="rating">
              <span class="pl-2 rating-text">Čistota ubytování:</span>
              <v-rating
                color="warning"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half-full"
                half-increments
                hover
                :readonly="!!review.id && review.approvalProcessState !== 2"
                length="5"
                size="25"
                v-model="form.cleanliness"
              ></v-rating>
            </div>
            <span class="rating-stars-numeric pb-2">( {{form.cleanliness}} )</span>
          </div>
          <div class="d-flex">
            <div class="rating">
              <span class="pl-2 rating-text">Vybavení:</span>
              <v-rating
                color="warning"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half-full"
                half-increments
                hover
                :readonly="!!review.id && review.approvalProcessState !== 2"
                length="5"
                size="25"
                v-model="form.equipment"
              ></v-rating>
            </div>
            <span class="rating-stars-numeric pb-2">( {{form.equipment}} )</span>
          </div>
          <div class="d-flex">
            <div class="rating">
              <span class="pl-2 rating-text">Poměr cena/kvalita:</span>
              <v-rating
                color="warning"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half-full"
                half-increments
                :readonly="!!review.id && review.approvalProcessState !== 2"
                hover
                length="5"
                size="25"
                v-model="form.qualityPriceRatio"
              ></v-rating>
            </div>
            <span class="rating-stars-numeric pb-2">( {{form.qualityPriceRatio}} )</span>
          </div>
        </v-col>
        <v-col cols="3">
          <div class="d-flex">
            <div class="rating">
              <span class="pl-2 rating-text">Okolí ubytování:</span>
              <v-rating
                color="warning"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half-full"
                half-increments
                hover
                :readonly="!!review.id && review.approvalProcessState !== 2"
                length="5"
                size="25"
                v-model="form.locality"
              ></v-rating>
            </div>
            <span class="rating-stars-numeric pb-2">( {{form.locality}} )</span>
          </div>
          <div class="d-flex">
            <div class="rating">
              <span class="pl-2 rating-text">Hostitel/Personál:</span>
              <v-rating
                color="warning"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half-full"
                half-increments
                hover
                :readonly="!!review.id && review.approvalProcessState !== 2"
                length="5"
                size="25"
                v-model="form.hostStaff"
              ></v-rating>
            </div>
            <span class="rating-stars-numeric pb-2">( {{form.hostStaff}} )</span>
          </div>
          <div class="d-flex">
            <div class="rating">
              <span class="pl-2 rating-text">Celkem:</span>
              <v-rating
                readonly
                color="warning"
                empty-icon="mdi-star-outline"
                full-icon="mdi-star"
                half-icon="mdi-star-half-full"
                half-increments
                hover
                length="5"
                size="25"
                :value="finalRating"
              ></v-rating>
            </div>
            <span class="rating-stars-numeric pb-2">( {{finalRating}} )</span>
          </div>
        </v-col>
      </v-row>
      <v-row class="pb-3">
        <div v-if="review.id">
          <v-chip :color="getReviewState().color">{{getReviewState().text}}</v-chip>
        </div>
      </v-row>
      <v-row>
        <v-btn
          color="primary"
          class="rounded-0"
          :disabled="!valid"
          :loading="loading"
          @click="save"
          v-if="!review.id || review.approvalProcessState === 2"
        >
          Uložit
        </v-btn>
      </v-row>
    </template>
    <v-skeleton-loader v-else type="article" />
  </v-form>
</template>

<script>
import { EventBus } from '@/events/event_bus'
export default {
  name: 'ReviewForm',
  props: {
    reservationId: {
      required: true,
      type: Number
    },
    propertyId: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      loading: false,
      fetchingReview: false,
      formValid: true,
      review: null,
      form: {
        text: null,
        cleanliness: null,
        equipment: null,
        qualityPriceRatio: null,
        locality: null,
        hostStaff: null
      }
    }
  },
  methods: {
    save () {
      this.loading = true
      this.$http({
        method: this.review.id ? 'PUT' : 'POST',
        url: this.review.id ? `/reviews/${this.review.id}` : '/reviews',
        data: {
          ...this.form,
          demandId: this.reservationId
        }
      })
        .then(async () => {
          await this.fetchReview()
          EventBus.$emit('review-final-update', { reservationId: this.reservationId, rating: this.review.finalRating })
        })
        .finally(() => {
          this.loading = false
        })
    },
    getReviewState () {
      if (this.review.approvalProcessState === 1) {
        return {
          text: 'Čekající',
          color: 'orange'
        }
      }
      if (this.review.approvalProcessState === 2) {
        return {
          text: 'Neschválená',
          color: 'red'
        }
      }
      if (this.review.approvalProcessState === 3) {
        return {
          text: 'Schválená',
          color: 'green'
        }
      }
      return {
        text: 'Smazaná',
        color: 'red'
      }
    },
    async fetchReview () {
      this.fetchingReview = true
      const review = await this.$http.get(`/reservations/${this.reservationId}/review`)
      this.review = review.data
      if (this.review.id) {
        this.form = { ...this.review }
        delete this.form.id
        delete this.form.approved
        delete this.form.approvedBy
      }
      this.fetchingReview = false
    }
  },
  computed: {
    finalRating () {
      const ratings = [this.form.cleanliness, this.form.equipment, this.form.qualityPriceRatio, this.form.locality, this.form.hostStaff]
      if (ratings.includes(undefined)) {
        return '-'
      }

      return ratings.reduce((prev, next) => prev + next, 0) / 5
    },
    valid () {
      return (this.form.cleanliness !== null &&
        this.form.equipment !== null &&
        this.form.qualityPriceRatio !== null &&
        this.form.locality !== null &&
        this.form.hostStaff !== null) && this.formValid
    }
  },
  created () {
    this.fetchReview()
  }
}
</script>

<style scoped>
.rating-stars-numeric{
  align-self: flex-end;
}
.rating-text{
  color: darkblue;
  font-weight: bold;
}
>>>.rating button{
  padding-top: 0;
}
</style>

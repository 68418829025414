<template>
  <v-container fluid id="user-demand-container">
    <v-row>
      <v-col cols="2" id="user-demand-nav">
        <v-list>
          <div
            v-for="(reservation, index) in reservations"
            :key="index">
            <v-list-item
              three-line
              link
              @click="showReservation(reservation)"
            >
              <v-list-item-content>
                <v-list-item-title>{{reservation.property.name}}</v-list-item-title>
                <v-list-item-subtitle>{{getLocalizedDate(reservation.dateFrom)}} - {{getLocalizedDate(reservation.dateTo)}}</v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-chip small :color="getLabel(reservation).color" class="mb-1 mt-1 rounded-0">{{getLabel(reservation).text}}</v-chip><br>
                  <v-rating v-if="reservation.review && reservation.review.finalRating"
                            :value="reservation.review.finalRating" class="item-rating" color="orange" half-increments
                            length="5" small></v-rating>
                  <span v-else>Hodnocení neuděleno</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
        <v-btn
          @click="getReservations"
          v-if="reservations.length < totalReservations"
        >
          Načíst další
        </v-btn>
      </v-col>
      <v-col>
        <ReservationDetail
          v-if="reservationDetail"
          :reservation="reservationDetail"
        />
        <v-card v-else-if="reservations.length === 0 && !runningRequest">
          <h3>Žádné rezervace k zobrazení</h3>
        </v-card>
        <v-skeleton-loader v-else type="image" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import demandsOverviewHelpers from '@/mixins/local/demandsOverviewHelpers'
import { mapGetters } from 'vuex'
import ReservationDetail from '@/views/user/ReservationDetail'
import { EventBus } from '@/events/event_bus'
export default {
  name: 'UserReservations',
  components: { ReservationDetail },
  mixins: [demandsOverviewHelpers],
  data () {
    return {
      runningRequest: false,
      page: 1,
      itemsPerPage: 10,
      totalReservations: null,
      reservations: [],
      reservationDetail: null
    }
  },
  methods: {
    showReservation (reservation) {
      this.reservationDetail = false
      this.$nextTick(() => {
        this.reservationDetail = reservation
      })
    },
    getReservations () {
      this.runningRequest = true

      this.$http.get(`/users/${this.loggedUser.id}/reservations`, {
        params: {
          page: this.page++,
          itemsPerPage: this.itemsPerPage
        }
      })
        .then(res => {
          this.reservations = [...this.reservations, ...res.data.demands]
          this.totalReservations = +res.data.total
        })
        .finally(() => {
          this.runningRequest = false
        })
    },
    getLabel (reservation) {
      const dateTo = new Date(reservation.dateTo)
      const today = new Date()

      if (today.getTime() < dateTo.getTime()) {
        return {
          color: 'green',
          text: 'Nadcházející'
        }
      } else {
        return {
          color: 'orange',
          text: ' Proběhlá'
        }
      }
    }
  },
  watch: {
    reservations: {
      deep: true,
      handler (newVal, oldVal) {
        if (!oldVal.length && newVal.length) {
          this.reservationDetail = newVal[0]
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      loggedUser: 'userAuth/user'
    })
  },
  created () {
    this.getReservations()
    EventBus.$on('review-final-update', ({ reservationId, rating }) => {
      const reservation = this.reservations.find(reservation => reservation.id === reservationId)
      if (reservation.review) {
        reservation.review.finalRating = rating
      }
    })
  }
}
</script>

<style scoped>
#user-demand-container{
  max-height: 100%;
  overflow: auto;
}
#user-demand-nav, #user-demand-nav>.v-list{
  background-color: #71bbff;
}
#user-demand-nav>.v-list{
  overflow: auto;
}
>>>.item-rating>.v-icon{
  padding: 2px !important;
}
</style>

import { render, staticRenderFns } from "./FeedbackForm.vue?vue&type=template&id=a6c56bd6&scoped=true&"
import script from "./FeedbackForm.vue?vue&type=script&lang=js&"
export * from "./FeedbackForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a6c56bd6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRating } from 'vuetify/lib/components/VRating';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCol,VContainer,VForm,VRating,VRow,VSkeletonLoader,VTextarea})

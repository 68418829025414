<template>
  <v-form v-if="!fetchingData" v-model="formValid">
    <v-container>
      <div class="text-h5 pt-3 pb-3">Zpětná vazba</div>
      <v-row>
        <v-col cols="12">
          <v-rating
            color="orange"
            length="3"
            half-increments
            v-model="form.rating"
            :rules="[validation.required]"
            size="22"
          >
          </v-rating>
        </v-col>
        <v-col cols="12">
          <v-textarea
            label="Popis"
            outlined
            dense
            v-model="form.text"
            :rules="[validation.required]"
            class="rounded-0"
          >
          </v-textarea>
        </v-col>
        <v-col>
          <v-btn
            color="primary"
            class="rounded-0"
            :disabled="!formValid"
            :loading=saving
            @click="save"
          >
            Odeslat
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
  <v-skeleton-loader v-else type="image"/>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'FeedbackForm',
  props: {
    reservationId: {
      type: Number
    }
  },
  data () {
    return {
      fetchingData: false,
      saving: false,
      formValid: true,
      form: {
        rating: 0,
        text: null
      }
    }
  },
  methods: {
    save () {
      this.saving = true
      const options = {
        method: this.form.id ? 'PUT' : 'POST',
        url: this.form.id ? `/feedback/${this.form.id}` : '/feedback',
        data: {
          ...this.form,
          demandId: this.reservationId
        }
      }
      this.$http(options)
        .then(res => {
          this.form = { ...res.data }
          window.scrollTo(0, 0)
          this.setAlerts([{
            message: 'Zpětná vazba uložena',
            type: 'success'
          }])
        })
        .finally(() => {
          this.saving = false
        })
    },
    ...mapActions({
      setAlerts: 'alerts/setAlerts'
    })
  },
  created () {
    this.fetchingData = true
    this.$http.get(`/reservations/${this.reservationId}/feedback`)
      .then(res => {
        this.form = { ...res.data }
      })
      .finally(() => {
        this.fetchingData = false
      })
  }
}
</script>

<style scoped>

</style>
